import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Header from "../containers/layout/header/header-one";
import Footer from "../containers/layout/footer/footer-one";
import PageHeader from "../components/pageheader";
import CTAArea from "../containers/global/cta-area/section-one";
import ContactFormArea from "../containers/contact-us/contact-form-area";
import ContactInfoArea from "../containers/contact-us/contact-info-area";
import WhyArea from "../containers/index-infotechno/about-area";
import AboutServiceWrap from "../containers/index-infotechno/about-service-wrap";
import GradationArea from "../containers/index-services/gradation-area";

const EvolveFrameworkPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Evolve Framework" />
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Evolve Framework"
    />
    <main className="site-wrapper-reveal">
      <AboutServiceWrap>
        <GradationArea />
        <WhyArea />
      </AboutServiceWrap>
      <CTAArea email="info@evolveadvisors.co.za" />
    </main>
    <Footer />
  </Layout>
);

export default EvolveFrameworkPage;
